import React from "react";
import LongContent from "./LongContent";

function LegalPrivacy(props) {
  return (
    <LongContent>
    <h1><strong>EpochShift End User License Agreement</strong></h1><h5>Last Updated: &nbsp;15-Mar-2022</h5>
    <p>This End User License Agreement (“<strong>Agreement</strong>”) is a legal agreement between you or the company or legal entity you represent (“<strong>you</strong>,” “<strong>your</strong>” and “<strong>yours</strong>”) and Diakronos Solutions, Inc. (“<strong>Diakronos</strong>”) for the use of the <strong>EpochShift</strong> Software (defined below). By Using (as defined below) the Software or otherwise assenting to this Agreement, you (a)&nbsp;acknowledge that you have read, understand and agree to the terms and conditions of this Agreement, and (b)&nbsp;represent that you have the legal authority to enter into this Agreement on your own behalf or, if applicable, on behalf of the legal entity you represent. </p>
    
    <p>IF YOU DO NOT AGREE WITH ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT OR DO NOT HAVE SUCH AUTHORITY, YOU MAY NOT USE THE SOFTWARE. </p>
    <p>‍</p>
    <h4>1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;DEFINITIONS</h4><p>1.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>“Agreement”</strong> is defined in the preamble paragraph, above. </p>
    
    <p>1.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>“Authorized Hardware” </strong>means hardware on which you are authorized to Use the Software.<strong> </strong></p>
    <p>1.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>“User”</strong> means an employee or agent who you authorize to use the Software. </p>
    <p>1.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“<strong>Distributor Terms</strong>” means the agreements, terms, conditions, and policies of your Distributor, as applicable, governing your use of services provided by a Distributor – such as cloud computing or hosting services, or professional services – related to your use of the Software.. </p>
    <p>1.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>“Confidential Information”</strong> is defined in Section 5.1.</p>
    <p>1.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>“Core Dumps”</strong> is defined in Section 3.3.</p>
    <p>1.7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>“Discloser”</strong> is defined in Section 5.1.</p>
    <p>1.8&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>“Distributor”</strong> means an authorized distributor of the Software through which you obtain your access to the Software. Distributors may be authorized resellers or distributors, including those providing related cloud computing services, such as Amazon Web Services, Google Cloud Platform, orMicrosoft Azure.</p>
    <p>1.9&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; “<strong>Documentation</strong>” means any user manuals or any other documentation relating to the Software that Diakronos provides or makes available to you.</p>
    <p>1.10&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>“Expenses”</strong> is defined in Section 3.2.</p>
    <p>1.11&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>“Feedback”</strong> is defined in Section 2.6.</p>
    <p>1.12&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>“Fees”</strong> is defined in Section 4.1.</p>
    <p>1.13&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>“Initial Term”</strong> is defined in Section 6.1.</p>
    <p>1.14&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>“License Key”</strong> is defined in Section 2.1. </p>
    <p>1.15&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>“Diakronos”</strong> is defined in the preamble paragraph, above. </p>
    <p>1.16&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; “<strong>Order Form</strong>” means an ordering document of either Diakronos, a Distributor, or other authorized Diakronos distributor pursuant to which you obtain limited access to the Software. In all cases, the Order Form must be agreed to by you and Diakronos, a Distributor, or other authorized Diakronos distributor.</p>
    <p>1.17&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>“Personnel”</strong> is defined in Section 3.2.</p>
    <p>1.18&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>“Recipient”</strong> is defined in Section 5.1.</p>
    <p>1.19&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>“Renewal Term”</strong> is defined in Section 6.2.</p>
    <p>1.20&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>“Services”</strong> is defined in Section 3.2.</p>
    <p>1.21&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>“Software”</strong> means the Diakronos software products or services identified in an Order Form. </p>
    <p>1.22&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>“Software Warranty”</strong> is defined in Section 7.2.</p>
    <p>1.23&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>“Subscription Term”</strong> is defined in Section 6.2.</p>
    <p>1.24&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>“Support”</strong> is defined in Section 3.1.</p>
    <p>1.25&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>“Taxes”</strong> is defined in Section 4.2.</p>
    <p>1.26&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>“Third-Party License”</strong> is defined in Section 2.7.</p>
    <p>1.27&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>“Third-Party Software”</strong> is defined in Section 2.7.</p>
    <p>1.28&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>“Trial Software”</strong> is defined in Section 2.5.</p>
    <p>1.29&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“<strong>Use</strong>” or “<strong>Using</strong>” means to install, load, run, execute, access, activate the processing capabilities of the Software and display the results thereof.</p>
    <p>1.30&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>“Warranty Period”</strong> is defined in Section 7.2.</p>
    <p>1.31&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>“You,”</strong> “<strong>your</strong>” and“<strong>yours</strong>” are defined in the preamble paragraph, above. </p>
    <p>‍</p>
    <h4>2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;LICENSE</h4><p>2.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>License Grant. </strong>Subject to your compliance with the terms and conditions of this Agreement, the applicable Order Form, and any applicable Distributor Terms, Diakronos hereby grants you a limited, non-exclusive, non-transferable, non-sublicensable license during the Subscription Term (defined below), solely for your internal business or research purposes, to (a) Use the Software, in object code form only, on Authorized Hardware, and (b) Use the Documentation as reasonably necessary to Use the Software. You may make a reasonable number of copies of the Software and Documentation for archival and back-up purposes only, provided that you include on each such copy all copyright or other proprietary notices contained on the Software and Documentation. All Software will be delivered by electronic means unless otherwise specified on the applicable Order Form. Software will be deemed delivered when it is first made available for your download or Use. The Software is licensed subject to limitations set out in the Order Form, including, as applicable and without limitation, specified quantity and capacity limitations, or restrictions on the number of Users. Diakronos may use a license key, activation code, or other technology (in any case, a “License Key”) to activate the Software and permit its usage in accordance with the applicable term or license parameters. </p>
    <p>2.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Restrictions. </strong>Except as expressly permitted hereunder, you will not (a) decompile, disassemble or reverse engineer the Software, except to the extent expressly permitted by applicable law notwithstanding a contractual prohibition to the contrary, (b) modify, publish, transmit, license, sublicense, assign, transfer, sell, grant a security interest in, distribute, reproduce, create derivative or collective works from the Software or the Documentation, (c) disclose, divulge, communicate, or allow access to the Software or Documentation to any person except your Users or other parties expressly authorized hereunder (such as your Distributor), (d) allow the Software to be used on any hardware other than the Authorized Hardware, (e) allow the Software to be used on an external commercial rental, time-sharing, or subscription basis or service bureau arrangement, (f) remove or modify any proprietary markings or any notice of Diakronos's or its licensors’ proprietary rights included in the Software or Documentation, (g) publish a review of the Software, information regarding any bugs or defects in the Software, or the results of any benchmark tests run on the Software, in each case, without the prior written consent of Diakronos, (h) disable or circumvent the applicable License Key technology, or (i) permit or assist any third party in doing any of the foregoing. </p>
    <p>2.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Ownership.</strong> The Software and Documentation are licensed, not sold. You acknowledge that (a) all right, title and interest in all copies of the Software and Documentation, and all intellectual and proprietary rights therein, are and will remain with Diakronos or its third-party licensors, (b) no right or interest in the Software or Documentation is conveyed other than the limited license granted by this Agreement, (c) the Software and Documentation are protected by the copyright laws of the United States and by international treaties, and (d) the Software and Documentation embody valuable proprietary information of Diakronos.</p>
    <p>2.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Trial Software.</strong> Diakronos may, in its discretion, provide access to “pre-release” or “trial” software (collectively, “<strong>Trial Software</strong>”). Unless otherwise specified in the applicable Order Form (a) all Software is Trial Software, (b) You may only Use Trial Software on a non-production basis for no more than 30 days, and (c) Diakronos may terminate your license to Use such Trial Software at any time upon written notice. In addition, the following Sections of this Agreement do not apply to Trial Software: 3.1 (Support Services), 7.2 (Limited Warranty), and 8.1 (Indemnity by Diakronos).</p>
    <p>2.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Feedback.</strong> You may choose to provide Diakronos with suggestions, comments, ideas, corrections, improvements, feedback, or other information about the Software in either verbal or written form (collectively, “<strong>Feedback</strong>”). If you do provide Feedback to Diakronos, Diakronos may use and modify such Feedback in connection with any product or service or for any other purpose, without any obligations to you or restrictions of any kind.</p>
    <p>2.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Third-Party Software. </strong>The Software may contain or be provided with certain third-party software licensed by its owners under its own license (collectively, the “<strong>Third-Party Software</strong>”). Third-Party Software is subject to the terms of the third-party software license accompanying or otherwise applicable to that Third-Party Software (“<strong>Third-Party License</strong>”). The Third-Party Licenses are set forth in the Documentation. The terms of the applicable Third-Party License will apply to the Third-Party Software independent of the terms of this Agreement. You may not Use any component contained in or provided with the Software on a standalone basis or to interoperate with any program(s) other than the Software.</p>
    <p>2.7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>High Risk Activities. </strong>The Software is not designed or intended for use in medical, nuclear, aviation, navigation, military or other high-risk activities where failure of the Software could result in death, personal injury, and/or substantial property damage. You may not Use the Software for any such purposes, and Diakronos and its licensors expressly disclaim and are released from any responsibility or liability for any and all damages that may be incurred due to the use of the Software in such applications.</p>
    <p>‍</p>
    <h4>3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SUPPORT</h4><p>3.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Support Services.</strong> Provided you have no outstanding payments due and are otherwise in compliance with the terms and conditions of this Agreement, Diakronos will provide you with support for the Software during your Subscription Term as set forth on your Order Form (“<strong>Support</strong>”). Support will include answering technical questions and assisting with the basic operation of the Software. In addition, as part of Support and at no additional charge, Diakronos will make the latest updates of the Software to which you are entitled available to you. </p>
    <p>3.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Professional Services.</strong> Diakronos may agree to provide certain professional services with respect to the Software (“<strong>Services</strong>”) in addition to the standard support services described above. Any such Services and the applicable fees therefor will be the subject of a separate written agreement between you and Diakronos. In the absence of a separate written agreement governing the Services, the following terms shall apply. The fees for the Services will be as set forth on the applicable Order Form. In addition, you are responsible for all travel and living expenses (“<strong>Expenses</strong>”) incurred by Diakronos's employees, agents, and contractors (collectively, “<strong>Personnel</strong>”) while traveling to and from your facilities to perform Services. Unless otherwise agreed in the applicable Order Form, fees for Services and reasonable Expenses will be billed monthly, or after the conclusion of the Services. You must use reasonable efforts to (a) provide Diakronos's Personnel with such information, co-operation and support as may reasonably be required for Diakronos to provide the Services, (b) permit Diakronos's Personnel to access such of your systems, networks, premises and property as is necessary to perform the Services and ensure that Diakronos is granted sufficient authorization to use any third party systems, programs, or networks necessary to perform the Services, (c) ensure that all necessary consents, authorizations and licenses have been obtained so that Diakronos's provision of the Services does not breach any statutory or regulatory provisions (of whatever jurisdiction) relating to the use of and access to personal data, and (d) ensure the health and safety of Diakronos's Personnel engaged in providing the Services at your premises.</p>
    <p>‍</p>
    <h4>4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;FEES AND PAYMENT</h4><p>4.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Fees</strong>. You will pay to Diakronos or your Distributor all fees for the Software as set forth on the applicable Order Form (“<strong>Fees</strong>”) and in accordance with the payment terms set forth therein and any applicable Distributor Terms. If your Order Form is with Diakronos (a) all amounts not paid when due will accrue interest until paid at the lesser of 1.5% per month or the maximum rate allowed by applicable law, and (b) you will reimburse Diakronos for all costs incurred by Diakronos (included reasonable attorneys’ fees) in collecting past due amounts. Diakronos or your Distributor may increase the Fees for any applicable Renewal Terms (defined below) upon written notice to you. Except as expressly set forth in this Agreement or any applicable Distributor Terms, all Fees are non-cancelable and non-refundable. Diakronos will invoice you in advance and otherwise in accordance with the relevant Order Form. Unless otherwise stated in the Order Form, invoiced charges are due net 30 days from the invoice date. You are responsible for providing complete and accurate billing and contact information and notifying Diakronos of any changes to such information.</p>
    <p>4.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Taxes</strong>. The Fees and any other charges described in this Agreement do not include federal, state, or local sales, VAT, GST, foreign withholding, use, property, excise, service, or similar taxes (“<strong>Taxes</strong>”). You will (a) pay all such Taxes as required by applicable law, (b) cooperate with Diakronos or your Distributor by providing all information required to the calculation and invoicing of all applicable Taxes, and (c) provide a valid tax-exemption certificate for any Tax from which you claim exemption.</p>
    <p>4.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Audit</strong>. Diakronos and its licensors will have the right to gain access to, examine and audit, during normal business hours upon reasonable prior written notice to you, all of your locations, computing resources, records, accounts and other information for purposes of determining your compliance with this Agreement. You agree to provide reasonable assistance and access to information in the course of any such audit. Any such audit will be at Diakronos's expense provided that, if an audit reveals that you have exceeded the scope and number of licenses or have otherwise breached this Agreement, in addition to acquiring additional licenses or otherwise curing such breach, you will reimburse Diakronos for the reasonable cost of the audit. Diakronos will also have the right to share the results of any such audit with our licensors.</p>
    <p>‍</p>
    <h4>5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CONFIDENTIAL INFORMATION</h4><p>5.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;As used herein, “<strong>Confidential Information</strong>” means all nonpublic information disclosed by a party, its affiliates and licensors (as the “<strong>Discloser</strong>”) that is designated as confidential or that, given the nature of the information or circumstances surrounding its disclosure, should reasonably be understood to be confidential. Confidential Information includes, without limitation, (a) nonpublic information relating to ideas, features, functions, organization, structure, graphics, or user interfaces of the Software, and (b) performance benchmarks and other test results of the Software. Confidential Information does not include any information that the receiving party (as the “<strong>Recipient</strong>”) can document (i) is or becomes publicly available without breach of this Agreement, (ii) was known to Recipient at the time of receipt from Discloser, (iii) is received from a third party that is not subject to an obligation of confidentiality or whose disclosure of such information is not in violation of applicable law, or (iv) is independently developed by Recipient without reference to Confidential Information. Recipient may use Confidential Information only in connection with performance under this Agreement. Recipient will take all reasonable measures to protect Confidential Information from unauthorized disclosure, dissemination or use using the same degree of care Recipient uses to protect its own confidential information of a similar nature, but in no event less than a reasonable degree of care.</p>
    <p>‍</p>
    <h4>6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TERM AND TERMINATION</h4><p>6.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Term</strong>. This Agreement will commence as of the effective date of the applicable Order Form, and subject to earlier termination under this Section 6, will continue in effect for the term set forth in the Order Form (the “<strong>Initial Term</strong>”). If no Subscription Term is specified in the Order Form, the Initial Term will be for 1 year.</p>
    <p>6.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Renewal Terms.</strong> Unless otherwise provided in an Order Form, subscriptions will automatically renew for successive annual terms (each, a “<strong>Renewal Term,</strong>” and together with the Initial Term, the “<strong>Subscription Term</strong>”) unless either party provides prior written notice of non-renewal to the other party at least 30 days prior to the end of the then-current Initial Term or Renewal Term, as applicable. The Subscription Term may not be cancelled in whole or in part during any subscription period. Subscription Fees for Renewal Terms are subject to increase based upon prevailing rates at the time of renewal. </p>
    <p>6.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Termination.</strong> Either party may terminate this Agreement upon written notice to the other party, in the event that the other party (a) breaches any material terms or obligations under this Agreement and fails to cure such breach within 30 days after notice thereof, or (b) dissolves, is declared insolvent or bankrupt, makes an assignment for the benefit of creditors, has a receiver appointed, or is the subject of any proceeding under any bankruptcy or insolvency laws, which proceeding, if initiated against such party, is not dismissed within 30 days. If your Order Form is with Diakronos and you terminate this Agreement for an uncured material breach by Diakronos, Diakronos will provide you a prorated refund of any pre-paid Fees for the remainder of your Subscription Term, provided that such refund is Diakronos's sole and exclusive obligation and your sole and exclusive remedy for any such material breach by Diakronos. This Agreement will automatically terminate in the event that you violate Sections 2.1, 2.2, 2.7, or 5. You may terminate this Agreement and your Use of the Software at any time for convenience with or without notice to Diakronos, though you will not be entitled to a refund of any pre-paid Fees or relieved of any obligation to pay Fees as set out in an Order Form. </p>
    <p>6.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Termination by Your Distributor.</strong> Your Distributor may have the right to suspend or terminate your access to the Software pursuant to the Distributor Terms, which may also terminate your Use of the Software. Diakronos will not be obligated to provide you with or otherwise be liable for any refund in such event, including any Fees you may have pre-paid for the Software. </p>
    <p>6.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Effect of Termination.</strong> Upon any termination of this Agreement, you will (a) immediately cease all Use of the Software and Documentation, (b) return to Diakronos, destroy or erase all copies (electronic or otherwise) of the Software, Documentation, and any Confidential Information you may have received from Diakronos, and (c) within 10 days of such termination, furnish written notice of such return, destruction or erasure to Diakronos. &nbsp;</p>
    <p>6.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Survival. </strong>The following sections will survive any termination of this Agreement: 1, 2.3, 4.1 and 4.2 (solely with respect to any Fees and Taxes that remain outstanding as of the effective date of termination) 4.3, 5, 6.5, 6.6, 7.3, 9, and 10.</p>
    <p>‍</p>
    <h4>7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;WARRANTIES AND DISCLAIMER</h4><p>7.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Mutual Warranties.</strong> Each party hereby warrants to the other that: (a) it has the authority to enter into this Agreement, to grant the rights granted by it under this Agreement, and to perform its obligations under this Agreement, (b) it has provided accurate, complete, and truthful information on the Order Form, and (c) it will comply with all applicable laws and regulations as they apply to each party’s rights obligations under the Agreement.</p>
    <p>7.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Limited Software Warranty. </strong>Diakronos warrants that for a period of 90 days from the start of your Initial Term (the “<strong>Warranty Period</strong>”) the Software will function substantially in accordance with the Documentation (the “<strong>Software Warranty</strong>”). In the event that you notify Diakronos of a reproducible breach of the Software Warranty during the Warranty Period, Diakronos will, at its option and expense and as its sole and exclusive obligation and your sole and exclusive remedy for such breach (a) modify the Software so that it conforms to the Software Warranty, (b) replace the Software with other software of substantially similar functionality, or (c) if neither of the foregoing options are commercially practicable as determined in Diakronos's sole discretion, terminate this Agreement and, if your Order Form is with Diakronos, provide you a prorated refund of any pre-paid Fees for the remainder of your Initial Term. Diakronos will not be responsible for any breaches of the Software Warranty resulting from (i) the Use of the Software not in accordance with the Documentation, including Use in combination with any other products or services not contemplated by the Documentation, (ii) causes external to the Software, such as problems with any other software, hardware, network or other infrastructure with which the Software is used, including any cloud or hosting services, (iii) improper or negligent installation, configuration or Use or unauthorized Use of the Software, or (iv) Software which has been altered or modified by anyone other than Diakronos.</p>
    <p>7.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Disclaimer.</strong> EXCEPT FOR THE EXPRESS WARRANTY SET FORTH IN SECTION 7.2, ABOVE, THE SOFTWARE IS PROVIDED “AS IS”, AND DIAKRONOS AND ITS LICENSORS MAKE NO OTHER REPRESENTATIONS OR WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE OR NON-INFRINGEMENT, ALL OF WHICH ARE HEREBY EXCLUDED AND DISCLAIMED. WITHOUT LIMITING THE FOREGOING, DIAKRONOS DOES NOT WARRANT THAT THE SOFTWARE WILL MEET YOUR REQUIREMENTS, THAT THE OPERATION OF THE SOFTWARE WILL BE UNINTERRUPTED, SECURE OR ERROR FREE, OR THAT ALL DEFECTS IN THE SOFTWARE CAN OR WILL BE CORRECTED.</p>
    <p>‍</p>
    <h4>8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;INDEMNITY</h4><p>8.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>By Diakronos.</strong> Except as set forth in Section 8.2, Diakronos will defend at its expense any action brought against you to the extent that it is based on a claim that the Software, when Used in accordance with the Documentation and the terms and conditions of this Agreement, infringes a United States patent, copyright or trade secret of any third party, and Diakronos will pay any costs, damages and reasonable attorneys’ fees finally awarded against you in, or payable in settlement of, such action which are directly attributable to such claim, provided that (a) you notify Diakronos promptly in writing promptly of the claim, (b) you permit Diakronos to assume sole control of the defense, compromise or settlement of the claim, and (c) you provide to Diakronos reasonable cooperation, information and assistance in connection therewith. If a final injunction is obtained against your use of the Software by reason of infringement, or if in Diakronos's opinion the Software is likely to become the subject of a successful claim of such infringement, Diakronos may, at its option and expense, (i) procure for you the right to continue Using the Software, (ii) modify the Software so that is non-infringing, (iii) replace the Software with other software of substantially similar functionality. In the event none of the foregoing options are commercially practicable as determined in Diakronos's sole discretion, Diakronos will terminate this Agreement and, if your Order Form is with Diakronos, provide you a prorated refund of any pre-paid Fees for the remainder of your Subscription Term. This Section 8.1 states Diakronos's sole and exclusive obligation, and your sole and exclusive remedy, with respect to any claim that the Software infringes the intellectual property rights of any third party.</p>
    <p>8.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>By You. </strong>Diakronos will have no liability to you, and you will, on the terms set forth above, defend and indemnify Diakronos and its licensors against claims of infringement based on (a) Use of the Software not in accordance with the Documentation or in violation of the terms and conditions of this Agreement, (b) the Use or combination of the Software with any other software or hardware not provided by Diakronos, if such infringement would not have occurred but for such Use or combination, (c) any modification of the Software by anyone other than Diakronos, (d) the Use of other than the most current version of the Software, if such version was made available by Diakronos for no additional fees with notice that such version was being provided in order to avoid an alleged or potential infringement, or (e) claims of infringement of intellectual property rights of you or your affiliates. You will also defend, indemnify and hold Diakronos and its licensors harmless from and against any claims, damages, losses, liabilities, costs, and expenses (including reasonable attorneys’ fees) arising out of or relating to your breach of this Agreement, violation of any applicable law, your breach of any applicable Distributor Terms, or any misuse of the Software or violation of Diakronos's or its licensors’ rights therein by your Distributor.</p>
    <p>‍</p>
    <h4>9.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;LIMITATION OF LIABILITY</h4><p>9.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;IN NO EVENT WILL DIAKRONOS OR ITS LICENSORS BE LIABLE TO YOU OR ANY THIRD PARTY FOR LOSS OF PROFITS, GOODWILL, USE OF DATA, OR FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. DIAKRONOS'S AND ITS LICENSORS’ TOTAL AGGREGATE LIABILITY UNDER THIS AGREEMENT, WHETHER IN CONTRACT, TORT, OR OTHERWISE, WILL NOT EXCEED THE SUBSCRIPTION FEES ACTUALLY PAID BY YOU AND RECEIVED BY DIAKRONOS IN THE 12 MONTHS PRECEDING THE EVENT THAT GAVE RISE TO LIABILITY.</p>
    <p>‍</p>
    <h4>10.&nbsp;&nbsp;&nbsp;MISCELLANEOUS</h4><p>10.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Assignment. </strong>You may not delegate any of your obligations under this Agreement, or assign or transfer this Agreement or any of your rights hereunder, whether by operation of law or otherwise, without the prior written consent of Diakronos. A change of control involving you will constitute an assignment for purposes of the foregoing restriction. Any delegation, assignment or transfer of this Agreement in violation of this Section 10.1 will be void and of no force and effect and a material breach of this Agreement. Diakronos may freely assign or transfer this Agreement. Subject to the foregoing, this Agreement will be binding upon, and inure to the benefit of the parties and their respective successors and assigns.</p>
    <p>10.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Compliance with Laws. </strong>You will comply with all applicable laws and regulations relating to your Use of the Software, including without limitation, those relating to export and import, privacy, and personal data protection.</p>
    <p>10.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Entire Agreement. </strong>This Agreement and any applicable Order Forms set forth the complete understanding between you and Diakronos with respect to the subject matter hereof and supersedes all prior understandings and communications relating thereto. No term or condition of a purchase order or other document you submit to Diakronos which is different from, inconsistent with, or in addition to the terms and conditions set forth herein will be binding upon Diakronos. To the extent that this document may constitute an acceptance, this acceptance is expressly conditioned on your assent to the terms and conditions set forth herein. Notwithstanding the foregoing, if you have entered into a separate written agreement with Diakronos for Use of the Software or the receipt of Services, the terms and conditions of such other agreement shall prevail over any conflicting terms or conditions in this Agreement.</p>
    <p>10.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Export Control. </strong>You may not Use or otherwise export or reexport the Software except as authorized by United States law and the laws of the jurisdiction in which the Software was obtained. In particular, but without limitation, the Software may not be exported or reexported (a) into any U.S. embargoed countries, or (b) to anyone on the U.S. Treasury Department's list of Specially Designated Nationals or the U.S. Department of Commerce Denied Person’s List or Entity List. By Using the Software, you represent and warrant that you are not located in any such country or on any such list. You also agree that you will not Use the Software for any purposes prohibited by United States law, including, without limitation, the development, design, manufacture, or production of missiles, nuclear, chemical or biological weapons.</p>
    <p>10.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Anti-Corruption.</strong> You must comply with all anti-corruption and anti-bribery laws (including, without limitation, the US Foreign Corrupt Practices Act, the UK Bribery Act and Proceeds of Crime Act, and commercial bribery laws). You may not offer, pay, promise or authorize any direct or indirect payments of anything of value for the purpose of obtaining business to any government official (including any person holding an executive, legislative, judicial or administrative office, whether elected or appointed, or any official or employee of any public international organization, such as the United Nations or World Bank, or any person acting in any official capacity for or on behalf of such government, public enterprise or state-owned business), any political party or party official, any political candidate or to any person or entity whom you know or should know will give part of the payments to any of the previously mentioned categories of people. </p>
    <p>10.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Force Majeure</strong>. Neither party will be responsible for any failure to perform its obligations under this Agreement (other than obligations to pay money) caused by an event beyond its reasonable control, including but not limited to, wars, riots, labor strikes, natural disasters, the infrastructure of the Internet, or any law, regulation, ordinance or other act or order of any court, government or governmental agency.</p>
    <p>10.7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Governing Law; Disputes.</strong> This Agreement is governed and will be construed by the laws of the Commonwealth of Massachusetts, excluding (a) that body of law known as conflicts of law, (b) the Uniform Computer Information Transactions Act, and (c) the United Nations Convention on Contracts for the Sale of Goods. Each party to this Agreement irrevocably submits to the exclusive jurisdiction of the state courts located in Middlesex or Suffolk County, Massachusetts (or, if there is federal jurisdiction, the United Stated District Court for the District of Massachusetts) for the determination of any action arising out of or in connection with this Agreement. Notwithstanding the foregoing, Diakronos may commence appropriate legal action in any jurisdiction to protect its Products or Confidential Information.</p>
    <p>10.8&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Government End Users. </strong>The Software and Documentation are “Commercial Items”, as that term is defined at 48 C.F.R. 2.101, consisting of “Commercial Computer Software” and “Commercial Computer Software Documentation”, as such terms are used in 48 C.F.R. 12.tw2 or 48 C.F.R. 227.7202, as applicable. Consistent with 48 C.F.R. 12.212 or 48 C.F.R. 227.7202-1 through 227.7202-4, as applicable, the Commercial Computer Software and Commercial Computer Software Documentation are being licensed to U.S. Government end users and U.S. Government contractors (a) only as Commercial Items and (b) with only those rights as are granted to all other end users pursuant to the terms and conditions herein.</p>
    <p>10.9&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Modification; Waiver.</strong> This Agreement may not be modified or amended except pursuant to a written instrument signed by both parties. The waiver by either party of a breach of any provision hereof will not be construed as a waiver of any succeeding breach of the same or any other provision, nor will any delay or omission on the part of such party to avail itself of any right, power or privilege that it has or may have hereunder operate as a waiver of any right, power or privilege.</p>
    <p>10.10&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Publicity.</strong> Diakronos may include your name and logo on its customer lists and reference the fact that you are a customer of Diakronos. However, neither party may issue a press release regarding this Agreement without the other party’s prior written approval (which will not be unreasonably withheld or delayed). </p>
    <p>10.11&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Notices. </strong>Notices in connection with this Agreement must be in writing and either delivered in person or by recognized commercial courier or certified mail, postage and fees prepaid, return receipt requested, and addressed (a) if to Diakronos, to Diakronos, Inc., 1 Broadway FL14, Cambridge, MA 02142-1187 USA, Attention: Legal Department, and (b) if to you, to the address set forth on the applicable Order Form, or to such other address as you have specified by notice hereunder. Notices will be deemed effective when received or, if delivery is refused, when delivery is attempted.</p>
    <p>10.12&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Relationship of Parties.</strong> Diakronos and you are independent contractors, and neither party, nor any of their respective affiliates, is an agent of the other for any purpose or has the authority to bind the other.</p>
    <p>10.13&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Severability.</strong> In the event that any provision of this Agreement is for any reason held invalid, illegal or unenforceable in any respect by a court of competent jurisdiction, to such extent such provision will be deemed null and void and severed from this Agreement, and the remainder hereof will remain in full force and effect.</p>
    <p>‍</p>
    <h5>[END OF EULA TERMS]</h5><p>‍</p>
    
    
    </LongContent>
  );
}

export default LegalPrivacy;
