import React from "react";
import Meta from "./../components/Meta";
import PricingSection from "./../components/PricingSection";

function PricingPage(props) {
  return (
    <>
      <Meta title="Pricing" />
      <PricingSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Pricing"
        subtitle="EpochShift is currently in limited release – inquire here about receiving access to a software license.  We offer a basic package and an expanded package that gives you a direct channel to the EpochShift team including prioritized support, feature requests, and early access to new features."
      />
    </>
  );
}

export default PricingPage;
