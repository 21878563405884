import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Section from "./Section";
import { Link } from "./../util/router";
import "./Footer.scss";

function Footer(props) {
  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      className="footer"
    >
      <Container fluid="lg">
        <Row>
          <Col>
            <Link to="/">
              <div>
                <img
                  className="FooterComponent__logo"
                  src={props.logo}
                  alt="Logo"
                />
              </div>
            </Link>

            {props.description && (
              <p className="FooterComponent__description">
                {props.description}
              </p>
            )}

            <p className="FooterComponent__copyright">
              {props.copyright}
              <Link to="/legal/terms-of-service">Terms</Link>
              <Link to="/legal/privacy-policy">Privacy</Link>
            </p>
          </Col>
          <Col md={{ span: 7, offset: 1 }} lg={{ span: 6, offset: 2 }}>
            <div className="FooterComponent__menus">
              <Row>
                <Col xs={12} md={4} className="mt-3 mt-md-0">
                  <h5>Product</h5>
                  <ul className="list-unstyled">
                    <li>
                      <Link to="/features">
                        <i className="fa-regular fa-lightbulb" />
                        &nbsp;&nbsp;&nbsp;Features
                      </Link>
                    </li>
                    <li>
                      <Link to="/pricing">
                        <i className="fa-regular fa-dollar" />
                        &nbsp;&nbsp;&nbsp;Pricing
                      </Link>
                    </li>
                    <li>
                      <Link to="/faq">
                        <i className="fa-regular fa-question" />
                        &nbsp;&nbsp;&nbsp;FAQ
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact">
                        <i className="fa-regular fa-envelope" />
                        &nbsp;&nbsp;&nbsp;Contact
                      </Link>
                    </li>
                  </ul>
                </Col>
                <Col xs={12} md={4} className="mt-3 mt-md-0">
                  <h5>Company</h5>
                  <ul className="list-unstyled">
                    <li>
                      <Link to="/about">
                        <i className="fa-solid fa-book" />
                        &nbsp;&nbsp;&nbsp;About
                      </Link>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://medium.com/the-tradespace"
                      >
                        <img
                          src="/images/mediumlogo.png"
                          alt="Medium"
                          width="30px"
                          style={{
                            position: "relative",
                            left: "-5px",
                            top: "-5px",
                            marginRight: "-4px",
                          }}
                        />
                        <span>Blog</span>
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.linkedin.com/company/diakronos-solutions/"
                      >
                        <img
                          src="/images/LinkedIn_small.png"
                          alt="LinkedIn"
                          width={"20px"}
                          style={{ marginRight: "2px" }}
                        />{" "}
                        <span>Profile</span>
                      </a>
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  );
}

export default Footer;
