import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import "./FaqSection.scss";

function FaqSection(props) {
  // Object to store expanded state for all items
  const [expanded, setExpanded] = useState({});
  // Set an item's expanded state
  const setExpandedItem = (index, isExpanded) => {
    setExpanded({
      ...expanded,
      [index]: isExpanded,
    });
  };

  const items = [
    {
      question: "What is Multi-Attribute Tradespace Exploration?",
      answer:
        'Multi-Attribute Tradespace Exploration (MATE for short) is a holistic engineering design methodology conceived and developed at MIT over the past 20 years. It was designed to be an efficient, iterative process for improved decision-making where alternatives and uncertainties abound. It has been wildly successful when used in early-lifecycle concept selection and design of aerospace, space, naval, and other complex systems, affording senior decision makers more insight than ever before. It has even been accused of being "more fun" by stakeholders who found themselves highly engaged in the design process.',
    },
    {
      question: "Why use MATE?",
      answer:
        <ul>
          <li>Focus on finding solutions that deliver value to stakeholders</li>
          <li>Take less time to conduct more rigorous studies</li>
          <li>Gain more confidence you have not missed key risks</li>
          <li>Develop an understanding of complex design spaces</li>
          <ul><li>Find design drivers of performance and cost</li>
          <li>Find performance and cost tradeoffs</li>
          <li>Find solutions attractive to each or every stakeholder</li>
          <li>Discover how uncertainty impacts cost and performance and find solutions robust to risk</li>
          </ul>
          <li>Foster cross-domain communication and understanding using a common language</li>
        </ul>
    },
    {
      question: "Why is the software called EpochShift?",
      answer:
        'In Epoch-Era Analysis, a methodology co-developed alongside MATE at MIT, an “epoch shift” denotes a long-lasting change in the world.  When you start using EpochShift to power up your tradespace exploration with MATE, we don’t think you’ll ever go back.',
    },
    {
      question: "Does EpochShift replace my existing interactive visualization software?",
      answer:
        "No, EpochShift is built to complement your existing workflows by providing an additional layer that draws a connection from your data directly back to the decision-makers and their needs.  It is lightweight and requires minimal effort to setup, so you can easily generate new insights that supercharge your existing analysis.",
    },
    {
      question: "Does EpochShift replace my existing evaluative models?",
      answer:
        "No, EpochShift is not a model itself, but it is flexible enough to import data from any of your existing models.  Once you’ve imported your data, EpochShift helps you add a new layer of value information on top of the raw data, making it easier to understand the tradespace and rapidly identify desirable solutions.",
    },
    {
      question: "I still have a lot of questions. Who can I talk to?",
      answer:<>We're glad to help! You can email us at <a href='mailto:info@diakronos.com'>info@diakronos.com</a>.
      </>
      
    },
  ];

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        />

        {items.map((item, index) => (
          <article
            className="FaqSection__faq-item py-4"
            onClick={() => {
              setExpandedItem(index, !expanded[index]);
            }}
            key={index}
          >
            <h4>
              <span className="text-primary mr-3">
                <i
                  className={
                    "fas" +
                    (expanded[index] ? " fa-minus" : "") +
                    (!expanded[index] ? " fa-plus" : "")
                  }
                />
              </span>
              {item.question}
            </h4>

            {expanded[index] && <div className="mt-3">{item.answer}</div>}
          </article>
        ))}
      </Container>
    </Section>
  );
}

export default FaqSection;
