import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import FormField from "./FormField";
import newsletter from "./../util/newsletter";
import { LinkContainer } from "react-router-bootstrap";

function Newsletter(props) {
  const [subscribed, setSubscribed] = useState(false);
  const { handleSubmit, register, errors } = useForm();

  const onSubmit = ({ email }) => {
    setSubscribed(true);
    // Parent component can optionally
    // find out when subscribed.
    props.onSubscribed && props.onSubscribed();
    // Subscribe them
    newsletter.subscribe({ email });
  };

  return (
    <>
      {subscribed === false && (
        
            <Col xs="auto">
              <a href="https://medium.com/the-tradespace" target="new" style={{color:"white"}}>
                <Button variant={props.buttonColor} size="lg">
                {props.buttonText}
              </Button></a>
            </Col>
        
      )}

      {subscribed === true && <div>{props.subscribedMessage}</div>}
    </>
  );
}

export default Newsletter;
