import React from "react";
import "./../styles/global.scss";
import NavbarCustom from "./../components/NavbarCustom";
import IndexPage from "./index";
import AboutPage from "./about";
import FaqPage from "./faq";
import ContactPage from "./contact";
import PricingPage from "./pricing";
import LegalPage from "./legal";
import DownloadPage from "./download";
import { Switch, Route, Router } from "./../util/router";
import NotFoundPage from "./404";
import Footer from "./../components/Footer";
import FeaturesPage from "./features";
import { Analytics } from "@vercel/analytics/react";

function App(props) {
  return (
    <Router>
      <>
      <Analytics/>
        <NavbarCustom
          bg="white"
          variant="light"
          expand="md"
          logo="/logo.png"
        />

        <Switch>
          <Route exact path="/" component={IndexPage} />

          <Route exact path="/about" component={AboutPage} />

          <Route exact path="/faq" component={FaqPage} />

          <Route path="/contact/:tier" component={ContactPage} />
          <Route exact path="/contact" component={ContactPage} />

          <Route exact path="/pricing" component={PricingPage} />

          <Route exact path="/features" component={FeaturesPage} />
          <Route exact path="/legal/:section" component={LegalPage} />
        <Route path="/download/:key" component={DownloadPage}/>
        <Route exact path="/download" component={DownloadPage}/>
          <Route component={NotFoundPage} />
        </Switch>

        <Footer
          bg="white"
          textColor="dark"
          size="md"
          bgImage=""
          bgImageOpacity={1}
          description="Trusted insights. Trusted decisions."
          copyright={`© ${new Date().getFullYear()} Diakronos Solutions, Inc`}
          logo="/logo.png"
        />
      </>
    </Router>
  );
}

export default App;
