import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Avatar from "./Avatar";

function TeamBiosSection(props) {
  const items = [
    {
      avatar: "/images/amr.jpg",
      name: "Dr. Adam Ross",
      role: "Founder, President",
      bio: "MIT/Harvard alum. Co-founder and former Lead Research Scientist of Systems Engineering Advancement Research Initiative @ MIT, 100+ publications and 14 awards. Former Director of Multi-Attribute Tradespace Exploration at private company before EpochShift.",
    },
    {
      avatar: "/images/mef3.png",
      name: "Dr. Matthew Fitzgerald",
      role: "MATE Developer",
      bio: "MIT alum. Research & practice in engineering design (aerospace, design for flexibility, and multi-stakeholder), modeling & simulation, data analysis. Over 19 publications and 5 awards. Built 4 iterations of tradespace exploration software tools before EpochShift.",
    },
    {
      avatar: "/images/mas.png",
      name: "Michael Schaffner",
      role: "MATE Developer",
      bio: "MIT alum. Courses+exp in systems engineering (mod/sim, ops research, stats, human factors), software dev, decision analysis, autonomous systems, technology and policy, philosophy and religious studies. Former entrepreneur, NASA Space Grant, Sandia National Labs. EpochShift enthusiast.",
    },
  ];

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        />
        <Row className="justify-content-center">
          {items.map((item, index) => (
            <Col
              xs={12}
              md={6}
              lg={4}
              className="py-3 d-flex align-items-stretch"
              key={index}
            >
              <Card>
                <Card.Body className="d-flex flex-column text-center align-items-center p-4">
                  <Avatar src={item.avatar} alt={item.name} size="128px" />
                  <h6 className="font-weight-bold mb-0 mt-4">{item.name}</h6>
                  <small>{item.role}</small>
                  <Card.Text className="mt-4">{item.bio}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  );
}

export default TeamBiosSection;
