import React from "react";
import Meta from "./../components/Meta";
import HeroSection from "./../components/HeroSection";
import FeaturesSection from "./../components/FeaturesSection";
import ClientsSection from "./../components/ClientsSection";
import TestimonialsSection from "./../components/TestimonialsSection";
import NewsletterSection from "./../components/NewsletterSection";
import CtaSection from "./../components/CtaSection";

function IndexPage(props) {
  return (
    <>
      <Meta />
      <HeroSection
        bg="primary"
        textColor="light"
        size="lg"
        bgImage="/images/header_background.png"
        bgImageOpacity={0.4}
        title="Got MATE?  EpochShift does."
        subtitle="And it's the only commercial software built on decades of MATE research."
        buttonText="Contact us today to inquire about a trial!"
        buttonColor="light"
        buttonPath="/contact"
      />
      <CtaSection
        bg="light"
        textColor="dark"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        title="Benefits"
        subtitle={
          <ul style={{textAlign:"left",fontSize:"1.3em"}}>
            <li>
            <i className="fa-solid fa-people-group" style={{fontSize:"2em",float:"right",marginTop:"-5px"}} />Quickly find valuable solutions for one to many stakeholders.<br/></li> 
            <li><i className="fa-solid fa-clock" style={{fontSize:"2em",position:"absolute",left:"-30px"}} />Conduct more rigorous trade studies in less time than ever. 
            </li>
            <li><i className="fa-solid fa-lightbulb" style={{fontSize:"2em",float:"right",marginTop:"15px"}} />
              See deep connections and experience new levels of confidence in exploring and understanding complex design spaces.
            </li>
            <li><i className="fa-solid fa-language" style={{fontSize:"2em",position:"absolute",left:"-30px"}} />
              Use a common language to communicate analysis questions and results across domains, unlocking true collaboration in your teams.
            </li>
          </ul>
        }
        buttonText=""
        buttonColor="light"
        buttonPath="/"
      />
      <FeaturesSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Features"
        subtitle={<>Multi-Attribute Tradespace Exploration (MATE) software,<br/>built by MATE researchers alongside MATE practitioners.</>}
      />
      <ClientsSection
        bg="dark"
        textColor="light"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        title="Across a wide array of industries..."
        subtitle=""
      />
      <TestimonialsSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="...Here's what people are saying:"
        subtitle=""
      />
      <CtaSection
        bg="dark"
        textColor="light"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        title="Want to Learn More?"
        subtitle=""
        buttonText="FAQ"
        buttonColor="light"
        buttonPath="/faq"
      />
      <CtaSection
        bg="primary"
        textColor="light"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        title="Ready to get started?"
        subtitle=""
        buttonText="Get Started"
        buttonColor="light"
        buttonPath="/pricing"
      />
      <NewsletterSection
        bg="dark"
        textColor="light"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Stay in the know"
        subtitle="Follow our blog for tips, case studies, and more"
        buttonText="Follow us on Medium"
        buttonColor="primary"
      />
    </>
  );
}

export default IndexPage;
