import React, { createRef, useRef } from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import AspectRatio from "./AspectRatio";
import "./FeaturesSection.scss";

function FeaturesDetailSection(props) {
  const items = [
    {
      title: "Fast, Easy Data Import",
      body: "Give EpochShift a data file, tell it which columns define the decision, which are uncertain, and which describe the result.  The importer will organize your data into a proper MATE study, provide a summary for sanity-checking, and you're off to the (tradespace exploration) races!",
      image: "/images/dataimport.png",
      icon: "fa-solid fa-file-import",
      video: "/video/EpochShiftIntro_DataImporter.mp4",
      startLocation: 67.0,
    },
    {
      title: "Simple Value Modeling",
      body: "Modeling stakeholder values can be difficult, but it doesn't have to be. EpochShift provides built-in tooling for automatically creating value models and interactively editing them, allowing you to revise, preview changes, and reassign value models for enhanced confidence in trade study results.",
      image: "fa-solid fa-scale-unbalanced",
      video: "/video/EpochShiftIntro_MAU.mp4",
      startLocation: 58.0,
    },
    {
      title: "Easy Insight Tracking",
      body: "Blazes, takeaways, and notes help you and your team quickly and transparently distill complex trade studies into actionable insights and easily communicate results, all while providing traceability and trust.",
      image: "fa-solid fa-lightbulb",
      video: "/video/EpochShiftIntro_Blazes.mp4",
      startLocation: 48.0,
    },
    {
      title: "Easy Value Model Editing",
      body: "Our advanced value modeling widget provides interactive editing of any value models you've created, allowing you to revise, preview changes, and reassign value models among stakeholders for enhanced confidence in trade study results.",
      image: "fa-solid fa-scale-unbalanced",
      video: "/video/EpochShiftIntro_UtilityEditor.mp4",
      startLocation: 57.0,
    },
  ];

  const refHasLoaded = useRef(items.map(() => createRef()));
  const colors = [
    "darkred",
    "steelblue",
    "black",
    "brown",
    "darkgoldenrod",
    "darkgreen",
  ];

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className="text-center">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
        />
        <Card style={{border:"none"}}>
        {items.map((item, index) => (
          <Row className="no-gutters overflow-hidden mt-5">
            
                <Col
                  sm={12}
                  md={6}
                  style={{
                    display: "flex",
                    alignItems: "stretch",
                    justifyContent: "left",
                    boxShadow: "1px 1px 0 0 #efefef",
                  }}
                >
                  {index % 2 === 0 ? (
                    <video
                      onPlay={(event) => {
                        if (!refHasLoaded.current[index].current) {
                          refHasLoaded.current[index].current = true;
                          event.target.currentTime = 0;
                        }
                      }}
                      width="600"
                      height="400"
                      preload={"metadata"}
                      controls
                    >
                      <source
                        src={item.video + "#t=" + item.startLocation}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <div className="FeaturesSection__item has-text-centered">
                      <h4>{item.title}</h4>
                      <p>{item.body}</p>
                    </div>
                  )}
                  {/* <AspectRatio ratio={4 / 3}>
                      <Image src={item.image} alt={item.title} fluid={true} />
                    </AspectRatio> */}
                </Col>
                <Col
                  sm={12}
                  md={6}
                  style={{
                    display: "flex",
                    alignItems: "stretch",
                    justifyContent: "right",
                    boxShadow: "1px 1px 0 0 #efefef",
                  }}
                >
                  {index % 2 !== 0 ? (
                    <video
                      onPlay={(event) => {
                        if (!refHasLoaded.current[index].current) {
                          refHasLoaded.current[index].current = true;
                          event.target.currentTime = 0;
                        }
                      }}
                      width="600"
                      height="400"
                      preload={"metadata"}
                      controls
                    >
                      <source
                        src={item.video + "#t=" + item.startLocation}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <div className="FeaturesSection__item has-text-centered">
                      <h4>{item.title}</h4>
                      <p>{item.body}</p>
                    </div>
                  )}
                </Col>
          </Row>
          ))}
          </Card>
      </Container>
    </Section>
  );
}

export default FeaturesDetailSection;
