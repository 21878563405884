import React from "react";
import Meta from "./../components/Meta";
import FeaturesDetailSection from "./../components/FeaturesDetailSection";

function FeaturesPage(props) {
  return (
    <>
      <Meta />
      <FeaturesDetailSection
        bg="white"
        textColor="dark"
        size="xl"
        bgImage=""
        bgImageOpacity={1}
        title="Features"
        subtitle="Built by MATE researchers alongside MATE practitioners"
      />
    </>
  );
}

export default FeaturesPage;
