import React from "react";
import LongContent from "./LongContent";

function LegalTerms(props) {
  return (
    <LongContent>
    <p><h1><strong>EPOCHSHIFT.APP WEBSITE TERMS OF SERVICE</strong></h1>
    </p>
    <p>Diakronos Solutions, Inc. ("Diakronos") provides use of its www.epochshift.app and other related websites, EpochShift software application, and consulting engagements (collectively, "Service(s)") to you subject to the following Terms of Service ("TOS"). You can review the most current version of the TOS at any time at: <a href="https://www.epochshift.app/legal/terms-of-service">https://www.epochshift.app/legal/terms-of-service</a>. In addition, when using the Services, you shall be subject to any posted guidelines, rules or terms applicable to such Services, which may be posted from time to time. All such guidelines, rules or terms are hereby incorporated by reference into the TOS. Diakronos may also offer other services that are governed by these or different terms of service or license terms.
    </p><p> THIS AGREEMENT IS A LEGAL AGREEMENT BETWEEN YOU AND DIAKRONOS AND GOVERNS YOUR USE OF THE SERVICES, INCLUDING ANY SUCCESSOR SERVICES. BY USING THE SERVICES YOU ARE INDICATING THAT YOU HAVE READ AND AGREE TO THESE TOS AND DIAKRONOS’S PRIVACY POLICY ("PRIVACY POLICY") AND, IF APPLICABLE, DIAKRONOS’S END USER LICENSE AGREEMENT ("EULA"), AND ANY OTHER RELATED TERMS AND CONDITIONS LOCATED ON DIAKRONOS’S WEBSITE, ALL OF WHICH ARE INCORPORATED HEREIN BY THIS REFERENCE. IF YOU DO NOT AGREE TO THESE TOS, DO NOT USE THE SERVICES. In the event any of the terms of these TOS conflict with the Privacy Policy or applicable EULA, the terms of the applicable EULA or Privacy Policy shall govern as to the conflicting terms. 
    </p><p> 1.0 DESCRIPTION OF SERVICE
</p><p> 
1.1 Unless explicitly stated otherwise, any new features that augment or enhance the current Service, including new releases, shall be subject to these or other applicable TOS. Diakronos may modify, terminate, suspend, or otherwise adjust any and all functions, features, options, utilities, tools or other aspects of the Service at any time without prior notice to you. The Service is provided "AS-IS" and Diakronos assumes no responsibility for, among other things, availability, timeliness, deletion, failure to store any user data or communications or personalization settings, or changes to the Service Diakronos may make from time to time. You are responsible for obtaining access to the Service, and that access may involve third-party fees (such as Internet service provider or airtime charges). In addition, you must provide and are responsible for all equipment necessary to access the Service.
The Service, any Diakronos proprietary software (“Diakronos Software”) and any other necessary software provided in connection with the Service contain proprietary and confidential information that is protected by applicable intellectual property and other laws. Content contained or information presented to you through the Service is or may be protected by copyrights, trademarks, service marks, patents or other proprietary rights and laws. Except as expressly permitted by applicable law or authorized by Diakronos, you may not modify, rent, lease, loan, sell, distribute or create derivative works based on the Service or the Diakronos Software or other software or materials, in whole or in part.
Diakronos, all Diakronos logos and product and service names are or may be trademarks of Diakronos (the "Diakronos Marks"). Except as solely enabled by any link as provided by Diakronos, you may not display or use in any manner the Diakronos Marks without Diakronos’s prior written consent.
By submitting ideas, suggestions, documents, and/or proposals ("Contributions") to Diakronos through its suggestion or feedback web pages or otherwise, you acknowledge and agree that: (a) your Contributions do not contain confidential or proprietary information; (b) Diakronos is not under any obligation of confidentiality, express or implied, with respect to the Contributions; (c) Diakronos shall be entitled to use or disclose (or choose not to use or disclose) such Contributions for any purpose, in any way, in any media worldwide; (d) Diakronos may have something similar to the Contributions already under consideration or in development; (e) your Contributions automatically become the property of Diakronos without any obligation of Diakronos to you; and (f) you are not entitled to any compensation or reimbursement of any kind from Diakronos under any circumstances.
</p><p> 2.0 YOUR USE OF THE SERVICE</p><p> 
2.1 Diakronos does not claim ownership of Content you submit or that you make available for inclusion on the Service. However, with respect to Content you submit or make available for inclusion on publicly accessible areas of the Service, you grant to Diakronos and all users of the Service a worldwide, royalty-free and non-exclusive, perpetual, irrevocable and fully sublicensable license to use, distribute, reproduce, modify, adapt, publish, translate, publicly perform and publicly display such Content (in whole or in part) and to incorporate such Content into other works in any format or medium now known or later developed.
Additionally, with respect to Content you submit or make available for inclusion on publicly accessible areas of the Services, you represent and warrant that: (a) you have the right to grant such license and make such Content available to Diakronos and its licensees, including users of the Service; (b) none of the Content you provide contains any data or information that is confidential, non-public, owned by or otherwise proprietary to a third party, not in the public domain or otherwise restricted or protected from use by any individual, entity or other party for any use by a third party; and (c) neither the delivery to nor use by Diakronos or its licensees, including users of the Service, of such content shall violate or infringe the rights of any third party.
</p><p> 2.2 You understand that all information, data, text, software, music, sound, photographs, graphics, video, messages, tags, or other materials ("Content"), whether publicly posted or privately transmitted, are the sole responsibility of the person from whom such Content originated. This means that you, and not Diakronos, are entirely responsible for all Content that you upload, download, post, email, transmit or otherwise make available or use via the Service. You understand that by using the Service, you may be exposed to Content that is offensive, indecent or objectionable.
You may not use the Service to:
<ul>
  <li>a. upload, post, email, transmit or otherwise make available any Content that:
<ul>
<li>	violates applicable law, including securities laws;</li>
<li>	contains any patient, medical or other protected health information regulated by the Health Insurance Portability and Accountability Act (“HIPAA”), as amended and supplemented) or any similar federal or state laws, rules or regulations;
</li><li>	contains any payment card information;
</li><li>	infringes any patent, trademark, trade secret, copyright or other proprietary rights ("Rights") of any party or use, modify or combine the Content in any manner, with any other material, in any fashion that infringes the Rights of any person;
</li><li>	you do not solely have the right to make available under applicable law or under contractual or fiduciary relationships (such as inside information, proprietary and confidential information learned or disclosed as part of employment relationships or under nondisclosure agreements); or
</li><li>	otherwise may create liability or damages to Diakronos;</li>
</ul>
</li><li>
b. impersonate any person or entity, including, but not limited to, a Diakronos official, forum leader, guide or host, or falsely state or otherwise misrepresent your affiliation with a person or entity;
</li><li> c. upload, post, email, transmit or otherwise make available any unsolicited or unauthorized advertising, promotional materials, "junk mail," "spam," "chain letters," "pyramid schemes," or any other form or solicitation, except in those areas of the Service that are specially and specifically designated for solicitation;
</li><li> d. upload, post, email, transmit or otherwise make available any material that contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment;
</li><li> e. interfere with or disrupt the Service or servers or networks connected to the Service;
</li><li> f. provide material support or resources (or to conceal or disguise the nature, location, source, or ownership of material support or resources) to any organization(s) designated by the United States government as a foreign terrorist organization pursuant to section 219 of the Immigration and Nationality Act;
</li><li> g. "stalk" or otherwise harass another; and/or
</li><li> h. collect or store personal data about other users in connection with the prohibited conduct and activities set forth in paragraphs a through g above.
</li>
</ul>
</p><p> 2.3 Diakronos may or may not pre-screen Content, but that Diakronos shall have the right (but not the obligation) to remove Content from the Service that Diakronos determines, in its discretion, may create liability or damages to Diakronos. You must evaluate, and bear all risks associated with, the use of any Content, including any reliance on the accuracy, completeness, or usefulness of such Content. 
</p><p> 2.4 Diakronos may access, preserve and disclose your account information and Content if required to do so by law or in a good faith belief that such access, preservation or disclosure is reasonably necessary to: (a) comply with legal process; (b) enforce the TOS; (c) respond to claims that any Content violates the rights of third parties; (d) respond to your requests for customer service; or (e) protect the rights, property or personal safety of Diakronos, its users and the public.
</p><p> 2.5 You understand that the technical processing and transmission of the Service, including your Content, may involve: (a) transmissions over various networks, and (b) changes to conform and adapt to technical requirements of connecting networks or devices.
</p><p> 2.6 You understand that the Service may include security components that permit digital materials to be protected, and that use of these materials is subject to usage rules set by Diakronos and/or content providers who provide such materials to the Service. You may not attempt to override or circumvent any digital rights management technology or usage rules embedded into the Service. Any unauthorized reproduction, publication, further distribution or public exhibition of the materials provided on the Service, in whole or in part, is strictly prohibited.
</p><p> 2.7 The Service may include functionality to provide access to products, services, information, data, content and other materials made available by or on behalf of third parties (collectively, “Third Party Materials”) or allow for the routing or transmission of Third Party Materials, including via links. By using such functionality, you are directing Diakronos to access, route and transmit to you the applicable Third Party Materials. Your installation or other use of Third Party Materials may result in the transmission of your information, data, content or other materials outside of your or Diakronos’s systems, or otherwise result in third party access to, or use of, your information, data, content or other materials. Third Party Materials are not part of the Services provided by Diakronos under these TOS. Diakronos does not control, has no obligation to monitor, and will have no responsibility or liability for any Third Party Materials. Diakronos makes no representation or warranty regarding any Third Party Materials or any information, content, data or other materials made available through any Third Party Materials. Diakronos may block or disable access to any Third Party Materials (in whole or part) through the Services at any time. In addition, the availability of any Third Party Materials or any other Services does not imply Diakronos’s endorsement of, or affiliation with, any provider of such Third Party Materials. Your use of Third Party Materials is subject to any additional terms, conditions and policies applicable to such Third Party Materials (such as terms of service or privacy policies of the providers of such Third Party Materials).
</p><p> 2.8 DIAKRONOS EXPRESSLY DISCLAIMS ANY AND ALL REPRESENTATIONS OR WARRANTIES RELATING TO ANY THIRD PARTY MATERIALS. YOU WILL LOOK SOLELY TO THE THIRD PARTY PROVIDER OF THE THIRD PARTY MATERIALS FOR ANY WARRANTY RELATED ISSUES OR OTHER CLAIMS RELATED THERETO. OTHERWISE, YOUR USE OF THIRD PARTY MATERIALS IS AT YOUR OWN RISK. DIAKRONOS WILL HAVE NO LIABILITY OR OTHER OBLIGATION OF ANY KIND ARISING OUT OF OR RELATED TO ANY THIRD PARTY MATERIALS OR THE USE OR INABILITY TO USE ANY THIRD PARTY MATERIALS. THE DISCLAIMERS, LIMITATIONS AND OTHER TERMS OF THESE TOS APPLICABLE TO THIRD PARTY MATERIALS WILL APPLY NOTWITHSTANDING ANY HOSTING OR OTHER SERVICES PROVIDED BY DIAKRONOS TO THE THIRD PARTY RELATED TO ANY SUCH THIRD PARTY MATERIALS.
</p><p> 
3.0 DISCLAIMER OF WARRANTIES
</p><p> 3.1 YOU AGREE THAT: (A) YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK, THE SERVICE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS AND DIAKRONOS AND ITS AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT; (B) DIAKRONOS AND ITS SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS MAKE NO WARRANTY THAT (i) THE SERVICE WILL MEET YOUR REQUIREMENTS, (ii) THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE, (iii) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICE WILL BE ACCURATE OR RELIABLE, (iv) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION OR OTHER MATERIAL OBTAINED BY YOU THROUGH THE SERVICE WILL MEET YOUR EXPECTATIONS, AND (v) ANY ERRORS IN THE SERVICE WILL BE CORRECTED; (C) ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE IS ACCESSED AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OR USE OF ANY SUCH MATERIAL; AND (D) NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM DIAKRONOS OR THROUGH OR FROM THE SERVICE SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE TOS. YOU MAY HAVE OTHER STATUTORY RIGHTS. HOWEVER, TO THE FULL EXTENT PERMITTED BY LAW, THE DURATION OF STATUTORILY REQUIRED WARRANTIES, IF ANY, SHALL BE LIMITED TO THE MINIMUM PERIOD ALLOWED BY LAW.
</p><p> 4.0 LIMITATION OF LIABILITY
</p><p> 4.1 DIAKRONOS AND ITS SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS SHALL NOT BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF DIAKRONOS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM: (i) THE USE OR THE INABILITY TO USE THE SERVICE; (ii) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION OR SERVICES OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH, FROM OR AS A RESULT OF THE SERVICE; (iii) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS, CONTENT, OR DATA; (iv) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SERVICE; OR (v) ANY OTHER MATTER RELATING TO THE SERVICE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS IN THIS PARAGRAPH MAY NOT APPLY TO YOU.
</p><p> 4.2 Diakronos has no responsibility or liability for the deletion or failure to store any Content, messages and other communications or other materials or Content maintained or transmitted by the Service.
</p><p> 5.0 INDEMNIFICATION
</p><p> 5.1 You agree to indemnify and hold Diakronos and its subsidiaries, affiliates, officers, agents, employees, partners and licensors harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of: (a) Content you submit, post, transmit or otherwise make available through the Service; (b) your use of the Service; (c) your connection to the Service; (d) your violation of the TOS; (e) your violation of any third party’s Rights; or (f) your use or involvement with a contract for hire engagement.
</p><p> 6.0 TERMINATION
</p><p> 6.1 Diakronos may, under any circumstances and without prior notice, immediately terminate your Diakronos account, and access to the Service and/or any and all Content or materials. Cause for such termination shall include, but not be limited to: (a) breaches or violations of the TOS or other incorporated agreements or guidelines, (b) requests by law enforcement or other government agencies, (c) a request by you (self-initiated account deletions), (d) discontinuance or material modification to the Service (or any part thereof), (e) unexpected technical or security issues or problems, (f) extended periods of inactivity, (g) engagement by you in fraudulent or illegal activities, and/or (h) nonpayment of any fees owed by you in connection with the Services. Diakronos may also terminate this Agreement, your Diakronos account, access to the Service and/or any and all Content or materials for any reason or no reason whatsoever. Termination of your Diakronos account includes: (a) removal of access to all offerings within the Service, (b) deletion of your password and all related information, files and content associated with or inside your account (or any part thereof), and (c) barring of further use of the Service. 
</p><p> 7.0 GENERAL
</p><p> 7.1 The TOS constitutes the entire agreement between you and Diakronos concerning your use of the Service, superseding any prior agreements between you and Diakronos with respect to the Service. You also may be subject to additional terms and conditions that may apply when you use or purchase certain other Diakronos services or Diakronos Software, affiliate services, third-party content or third-party software. The TOS and the relationship between you and Diakronos shall be governed by the laws of the Commonwealth of Massachusetts without regard to its conflict of law provisions. You and Diakronos agree to submit to the personal and exclusive jurisdiction of the courts located within the city of Boston, Massachusetts. The failure of Diakronos to exercise or enforce any right or provision of the TOS shall not constitute a waiver of such right or provision. If any provision of the TOS is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties' intentions as reflected in the provision, and the other provisions of the TOS remain in full force and effect. The section titles in the TOS are for convenience only and have no legal or contractual effect.
</p><p> 8.0 AMENDMENTS
</p><p> 8.1 This TOS is subject to occasional revision, and if we make any substantial changes, we may notify you by sending you an e-mail to the last e-mail address you provided to us (if any) and/or by prominently posting notice of the changes on our site. Any changes to this TOS will be effective no later than thirty (30) calendar days following our posting of notice of the changes on our Site. Continued use of our Service following notice of such changes shall indicate your acknowledgement of such changes and agreement to be bound by the terms and conditions of such changes.
</p><p> 9.0 YOUR REGISTRATION OBLIGATIONS
</p><p> 9.1 In consideration of your use of the Service, you represent that you are of legal age to form a binding contract and are not a person barred from receiving services under the laws of the United States or other applicable jurisdiction. In order to access or use certain Services, you may be required to provide certain information about yourself ("Registration Data") as part of the registration process for the Service, or as part of your continued use of the Services. You also agree to: (a) provide true, accurate, current and complete Registration Data as prompted or requested by the Service and (b) maintain and promptly update the Registration Data to keep it true, accurate, current and complete. The Service may include certain communications from Diakronos, such as service announcements and administrative messages, and that you may not be able to opt out of receiving them. If you provide any information that is untrue, inaccurate, not current or incomplete, or Diakronos has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, Diakronos has the right to suspend or terminate your account, prevent your access to any or all portion of the Services and or Content (or other users' access to your Content) and refuse any and all current or future use of the Service (or any portion thereof) or Content. You certify that you are at least 18 years old; if you are not, you may not register for or use the Service.
</p><p> 10.0 ACCOUNT, PASSWORD AND SECURITY
</p><p> 10.1 As part of your use of the Service, you may receive a password and account designation upon completing the Service's registration process. You are responsible for maintaining the confidentiality of the password and account and are fully responsible for all activities that occur under your password or account. You agree to immediately notify Diakronos of any unauthorized use of your password or account or any other breach of security. Diakronos cannot and will not be liable for any loss or damage arising from your failure to properly safeguard your account or password. You have sole responsibility for adequate protection and backup of Content of any type that you upload to, download from or otherwise create or use with, the Service, and/or equipment you use in connection with the Service.
</p><p> 11.0 CHARGED OFFERINGS AND FEES
</p><p> 11.1 The Service may be available to you with or without charge. Access to certain features, functions, and/or full or partial portions of the Service may require payment. You may also be provided "trial", "demo", "beta" or other limited-functionality or limited-access versions of the Service and/or its parts with or without charge. In addition to any fees required to access certain features, capabilities, functions or services offered through or by the Service ("Charged Offerings"), you are responsible for paying all applicable taxes (including, but not limited to those we are not required to collect) and for all third party hardware, software, service and/or other costs you may incur in order to access the Service and your account. Neither these TOS nor your account entitle you to any subsequent releases of the Services, nor to any for-pay features, products, capabilities, features, upgrades or services offered, distributed or made available through the Service without paying applicable charges or except as otherwise expressly and explicitly provided by Diakronos. All or some of the Service may need to be downloaded to your computer and/or accessed online. You must have authorized and separate Service access for each account you wish it to be enabled for and, where applicable, you must download and install any necessary software on each machine on which you wish to use and access the Service.
</p><p> 11.2 Converting an account and/or specific Service version or offering, including but not limited to trial or limited functionality rights offerings, to an enhanced account and/or fee-based version of the Service or other paid account, version or other Charged Offering may result in the loss of access to the information or other data associated with your use of the free or non-paid version of the Service.
</p><p> 11.3 You may terminate your account at any time. You will not receive full or partial refunds for subscription periods that you have purchased. Terminating a subscription for one Charged Offering does not necessarily terminate right(s) you may have for another Charged Offering, other portion of the Service, software or other offerings from Diakronos.
</p><p> 12.0 OBJECTING TO CONTENT
</p><p> If you believe that any Content provided through the Service infringes your copyright, you may notify Diakronos at legal@diakronos.com.
</p><p> Last Updated: March 14, 2022
</p>


    </LongContent>
  );
}

export default LegalTerms;
