import React from "react";
import Navbar from "react-bootstrap/Navbar";
import { NavDropdown, Nav } from "react-bootstrap";
import { Link } from "./../util/router";

function NavbarCustom(props) {
  return (
    <Navbar bg={props.bg} variant={props.variant} expand={props.expand}>
      <Navbar.Brand href="/">
        <img
          className="d-inline-block align-top"
          src={props.logo}
          alt="Logo"
          height="30"
        />
        <span style={{ margin: "20px" }}>EpochShift</span>
      </Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse>
        <Nav className="me-auto">
          <NavDropdown title="Product">
            <NavDropdown.Item href="/features">
              <i className="fa-regular fa-lightbulb" />
              &nbsp;&nbsp;&nbsp;Features
            </NavDropdown.Item>
            <NavDropdown.Item href="/pricing">
              <i className="fa-regular fa-dollar" />
              &nbsp;&nbsp;&nbsp;Pricing
            </NavDropdown.Item>
            <NavDropdown.Item href="/faq">
              <i className="fa-regular fa-question" />
              &nbsp;&nbsp;&nbsp;FAQ
            </NavDropdown.Item>
            <NavDropdown.Item href="/contact">
              <i className="fa-regular fa-envelope" />
              &nbsp;&nbsp;&nbsp;Contact
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="Company">
            <NavDropdown.Item href="/about">
              <i className="fa-solid fa-book" />
              &nbsp;&nbsp;&nbsp;About
            </NavDropdown.Item>
            <NavDropdown.Item
              target="_blank"
              rel="noopener noreferrer"
              href="https://medium.com/the-tradespace"
            >
              <img
                src="/images/mediumlogo.png"
                alt="Medium"
                width="30px"
                style={{
                  position: "relative",
                  left: "-5px",
                  top: "-5px",
                  marginRight: "-4px",
                }}
              />
              <span>Blog</span>
            </NavDropdown.Item>
            <NavDropdown.Item
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/company/diakronos-solutions/"
              // style={{ background: "none" }}
            >
              <img src="/images/LinkedIn_small.png" alt="LinkedIn" 
                          width={"20px"}
                          style={{ marginRight: "2px" }} />{" "}
              <span >Profile</span>
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavbarCustom;
