import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Media from "react-bootstrap/Media";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Avatar from "./Avatar";

function TestimonialsSection(props) {
  const items = [
    {
      name: " Sr. Science Manager",
      testimonial:
        "[Interactive MATE software] puts the fun back into systems engineering.",
      company: "U.S. Space Org",
    },
    {
      name: "High Ranking Officer",
      testimonial:
        "This [MATE] is how the DoD  should make all its [acquisition] decisions.",
      company: "Joint Chiefs, DoD",
    },
    {
      name: "Study Lead",
      testimonial:
        "I see A TON of value in the tool and the capability... It would save us a lot of time & effort internally, which would easily make it a positive return investement for us.",
      company: "Global energy company",
    },

  ];

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        />
        <Row className="justify-content-center">
          {items.map((item, index) => (
            <Col xs={12} md={4} className="py-3" key={index}>
              <Card>
                <Card.Body className="p-4">
                  <Card.Text><i>{item.testimonial}</i></Card.Text>
                  <Media className="align-items-center mt-auto">
                    <i className={(index%2===0?"fa-regular":"fa-solid")+" fa-user"}/>
                    <Media.Body className="ml-2">
                      <h6 className="font-weight-bold mb-0">{item.name}</h6>
                      <small>{item.company}</small>
                    </Media.Body>
                  </Media>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  );
}

export default TestimonialsSection;
