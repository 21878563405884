import React, { useRef } from "react";
import Meta from "./../components/Meta";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

const validKeys = ["a5f2bb3d1s","b2c1bb3d2s","c7c1bb3d3s","d3c1bb3d4s"];
function DownloadPage(props) {

    const downloadLink = 
    props.match.params.key && validKeys.indexOf(props.match.params.key) >= 0 ? (
      <a target="_blank"
        href="/files/EpochShift.zip"
        onClick={() => fetch("/api/download?key=" + props.match.params.key)}
      >
        EpochShift.zip
      </a>
    ) : null;
  return (
    <>
      <Meta title="Thank you for downloading" />
      <div
        style={{
          padding: "50px",
          width: "100%",
          textAlign: "center",
        }}
      >
        {downloadLink ? (
          <div>
            Thank you for your interest in EpochShift!  <p>Please click on the following link to download:<p> {downloadLink}</p></p>
            <p>If you encounter any technical difficulties with downloading or unpacking the .zip file, please
            contact us:{" "}
            <a href="mailto:help@diakronos.com">help@diakronos.com</a>.</p>
          </div>
        ) : (
          "You have reached this page in error. Perhaps you were looking for something else?"
        )}
      </div>
    </>
  );
}

export default withRouter(DownloadPage);
