import React from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import AspectRatio from "./AspectRatio";
import "./FeaturesSection.scss";
import CtaSection from "./CtaSection";

function FeaturesSection(props) {
  const items = [
    {
      title: "Fast, Easy Data Import",
      body: "Give EpochShift a data file, tell it which columns define the decision, which are uncertain, and which describe the result.  The importer will organize your data into a proper MATE study, provide a summary for sanity-checking, and you're off to the (tradespace exploration) races!",
      image: "fa-solid fa-file-import",
    },
    {
      title: "Advanced Visual Analysis",
      body: "Epochshift comes pre-loaded with all the visualizations and analysis you need for trade studies, all fully customizable to your data and your questions. Whether you're looking for the Pareto Front, a robust system design, or specific subsets of alternatives, EpochShift will help you find the solution.",
      image: "fa-solid fa-chart-area",
    },
    {
      title: "Simple Value Modeling",
      body: "Modeling stakeholder values can be difficult, but it doesn't have to be. EpochShift provides built-in tooling for automatically creating value models and interactively editing them, allowing you to revise, preview changes, and reassign value models for enhanced confidence in trade study results.",
      image: "fa-solid fa-scale-unbalanced",
    },
    {
      title: "Truly Strategic Decision-Making",
      body: "Leverage Epoch-Era Analysis to intuitively model lifecycle uncertainties. Looking for system robustness? One-click. Need a narrative example lifecycle for a presentation? Show the Era Sequence plot. Stakeholder values changing over time? Easily create temporal variables and new value models for future epochs, and instantly see the effects on the tradespace.",
      image: "fa-solid fa-chess-knight",
    },
    {
      title: "Easy Insight Tracking",
      body: "Blazes, takeaways, and notes help you and your team quickly and transparently distill complex trade studies into actionable insights and easily communicate results, all while providing traceability and trust.",
      image: "fa-solid fa-lightbulb",
    },
    {
      title: "Growing Capabilities",
      body: "EpochShift already comes with all the functionality needed to perform a standard MATE study, and continues to grow with an ever-expanding set of capabilities.  Look forward to advanced uncertainty analysis supported by AI insights in the near future!",
      image: "fa-solid fa-seedling",
    },
  ];
  const colors=["darkred","steelblue","black","brown","darkgoldenrod","darkgreen"]

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      featuresDetail={true}
    >
      <Container className="text-center">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={1}
          spaced={true}
        />
        <Card>
          <Row className="no-gutters overflow-hidden">
            {items.map((item, index) => (
              <Col
                xs={12}
                lg={6}
                style={{
                  display: "flex",
                  alignItems: "stretch",
                  justifyContent: "center",
                  boxShadow: "1px 1px 0 0 #efefef",
                }}
                key={index}
              >
                <div className="FeaturesSection__item has-text-centered">
                  <div className="FeaturesSection__image-container">
                    <i className={item.image} style={{fontSize:"3em",color:colors[index]}}/>
                    {/* <AspectRatio ratio={4 / 3}>
                      <Image src={item.image} alt={item.title} fluid={true} />
                    </AspectRatio> */}
                  </div>
                  <h4>{item.title}</h4>
                  <p>{item.body}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Card>
        <CtaSection
        bg="primary"
        textColor="light"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        title="See it in action"
        subtitle=""
        buttonText="Watch demos"
        buttonColor="light"
        buttonPath="/features"
      />
      </Container>
    </Section>
  );
}

export default FeaturesSection;
